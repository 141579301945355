const updateMetaTag = (name: string, content: string, isProperty = false) => {
  const selector = isProperty ? `meta[property="${name}"]` : `meta[name="${name}"]`;
  const metaTag = document.head.querySelector(selector);
  if (metaTag) metaTag.remove();

  const newMetaTag = document.createElement('meta');
  if (isProperty) {
    newMetaTag.setAttribute('property', name);
  } else {
    newMetaTag.name = name;
  }
  newMetaTag.content = content;
  document.head.appendChild(newMetaTag);
};

export const updateMetaTagsByCompany = (companyName: string, companyDescription: string, companyLogo: string) => {
  if (!companyName || !companyDescription || !companyLogo) return;

  const titleContent = `${companyName}-Simón`;

  // Meta title
  updateMetaTag('title', titleContent);

  // Meta description
  updateMetaTag('description', companyDescription);

  // Meta keywords
  updateMetaTag('keywords', `${companyName}, microlearning, capacitación, learning, microaprendizaje`);

  // meta URL
  updateMetaTag('url', window.location.href);
  // Twitter (X) meta tags
  updateMetaTag('twitter:card', 'summary');
  updateMetaTag('twitter:title', titleContent);
  updateMetaTag('twitter:description', companyDescription);
  updateMetaTag('twitter:image', companyLogo);
  // LinkedIn meta tags
  updateMetaTag('og:title', titleContent, true);
  updateMetaTag('og:description', companyDescription, true);
  updateMetaTag('og:image', companyLogo, true);
  updateMetaTag('og:image', companyLogo, true);
  updateMetaTag('og:type', 'website', true);
  updateMetaTag('og:url', window.location.href, true);
  updateMetaTag('og:site_name', companyName, true);
  updateMetaTag('og:locale', 'es_MX', true);
};