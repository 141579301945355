import { IConfiguration } from '../interfaces';

export const defaultStyles: IConfiguration['styles'] = {
   primaryColor: '#F9D53E',
   secondaryColor: '#2657b6',
   blackCustom: '#1C1B1A',
   whiteCustom: '#f4f4f4',
   backgroundPages: '#BCBEC6',
   textButtonColor: '#000000',
   barReproductionColor: '#F9D53E',
   backgroundButton: '#ffffff',
   textButton: '#000000',
   shadowColor: '#000000',
   backgroundRegister: '#000000',
   colorRegister: '#ffffff'
};

export const defaultSlug: string = 'simon';
