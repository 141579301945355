import { IConfiguration, IUserLocalStorage } from '../interfaces';

export function getSession(): IUserLocalStorage | null {
   // get item of localstorage
   const userSession: string | null = localStorage.getItem('user');
   // validate if any content in localstorage instance
   if (userSession) {
      let user: IUserLocalStorage = JSON.parse(userSession);
      return user;
   }

   return null;
}

export function getConfiguration(): IConfiguration | null {
   // get item of localstorage
   const configuration: string | null = localStorage.getItem('configuration');
   // validate if any content in localstorage instance
   if (configuration) {
      let configurationParse: IConfiguration = JSON.parse(configuration);
      return configurationParse;
   }

   return null;
}
