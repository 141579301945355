import { PureComponent, Suspense } from 'react';
import { Loading } from './library/components';

export function LazyLoader(WrappedComponent: React.ComponentType) {
   return class Wrapped extends PureComponent {
      render() {
         return (
            <Suspense fallback={<Loading />}>
               <WrappedComponent {...this.props} />
            </Suspense>
         );
      }
   };
}
