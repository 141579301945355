import { IConfiguration } from '../../../shared/interfaces';
import { useNavigate, useParams } from 'react-router-dom';

interface CustomErrorProps {
   inMaintenance?: boolean;
}

export function CustomError({ inMaintenance }: CustomErrorProps): JSX.Element {
   // configuration
   const { status } = useParams();
   const navigate = useNavigate();
   const configurationDataLocalStorage: string | null = localStorage.getItem('configuration');

   if (inMaintenance) {
      return (
         <div className='h-[100dvh] w-screen bg-white flex flex-col justify-center items-center absolute z-[11] top-0 text-red-500'>
            <img src='/images/maintenance.gif' alt='maintenance' className='h-[70dvh] rounded-lg' />
         </div>
      );
   }

   return (
      <div className='h-[100dvh] w-screen bg-white flex flex-col justify-center items-center absolute z-[11] top-0 text-red-500'>
         {status && status === '500' && (
            <>
               <h2>Lo sentimos, ha ocurrido un error:</h2>
               <p>500 Internal Server Error</p>
            </>
         )}
         {status && status === '401' && (
            <>
               <h2>Sesión expirada</h2>
               <button
                  onClick={() => {
                     if (configurationDataLocalStorage) {
                        let configurationData: IConfiguration = JSON.parse(configurationDataLocalStorage);
                        navigate(`/${configurationData.slugCompany}/auth`);
                     }
                     if (!configurationDataLocalStorage) {
                        navigate('/');
                     }
                  }}
                  className='h-[44px] px-4 bg-red-500 rounded-md text-white font-semibold text-[18px] mt-4'>
                  Iniciar sesión
               </button>
            </>
         )}
         {status && status === 'invalid' && (
            <>
               <h2>Token inválido</h2>
               <button
                  onClick={() => {
                     if (configurationDataLocalStorage) {
                        let configurationData: IConfiguration = JSON.parse(configurationDataLocalStorage);
                        navigate(`/${configurationData.slugCompany}/home`);
                     }
                     if (!configurationDataLocalStorage) {
                        navigate('/');
                     }
                  }}
                  className='h-[44px] px-4 bg-red-500 rounded-md text-white font-semibold text-[18px] mt-4'>
                  Iniciar
               </button>
            </>
         )}
      </div>
   );
}
