import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface IVideo {
   url: string;
   paused: boolean;
   sound: boolean;
   finished: boolean;
   percentageInteraction: boolean;
   reproductionPercentage: number;
   repeatVideo: boolean;
   showQuiz: boolean;
   showPracticalActivity: boolean;
   showVideoComponent: boolean;
}

const initialState: IVideo = {
   url: '',
   paused: false,
   sound: false,
   finished: false,
   percentageInteraction: false,
   reproductionPercentage: 0,
   repeatVideo: false,
   showQuiz: false,
   showPracticalActivity: false,
   showVideoComponent: true
};

export const videoSlice = createSlice({
   name: 'video',
   initialState,
   reducers: {
      updateVideoUrl: (state, action: PayloadAction<string>): void => {
         state.url = action.payload;
         state.paused = false;
      },
      pauseVideo: (state, action: PayloadAction<boolean>): void => {
         state.paused = action.payload;
      },
      soundSwitch: (state, action: PayloadAction<boolean>): void => {
         state.sound = action.payload;
      },
      finishedVideo: (state, action: PayloadAction<boolean>): void => {
         state.finished = action.payload;
      },
      percentageInteractionSwitch: (state, action: PayloadAction<boolean>): void => {
         state.percentageInteraction = action.payload;
      },
      updateReproductionPercentage: (state, action: PayloadAction<number>): void => {
         state.reproductionPercentage = action.payload;
      },
      replayVideo: (state, action: PayloadAction<boolean>): void => {
         state.repeatVideo = action.payload;
      },
      setShowQuiz: (state, action: PayloadAction<boolean>): void => {
         state.showQuiz = action.payload;
      },
      setShowPracticalActivity: (state, action: PayloadAction<boolean>): void => {
         state.showPracticalActivity = action.payload;
      },
      setShowVideoComponent: (state, action: PayloadAction<boolean>): void => {
         state.showVideoComponent = action.payload;
      }
   }
});

export const {
   updateVideoUrl,
   pauseVideo,
   soundSwitch,
   finishedVideo,
   percentageInteractionSwitch,
   updateReproductionPercentage,
   replayVideo,
   setShowQuiz,
   setShowPracticalActivity,
   setShowVideoComponent
} = videoSlice.actions;

export default videoSlice.reducer;
