import { getConfiguration } from '../../../shared/hooks/user-session.hook';
import { defaultSlug } from '../../../shared/mocks/configuration.mock';
import { IConfiguration } from '../../../shared/interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

interface RedirectHomeProps {
   urlShort?: boolean;
}

export function RedirectHome({ urlShort }: RedirectHomeProps) {
   // configuration
   const navigate = useNavigate();
   const { slugCompany } = useParams();
   const domain = window.location.hostname;
   const configurationLS: IConfiguration | null = getConfiguration();

   // effects
   useEffect(() => {
      // if the domain is 'www.empresariopulpero.com' or 'empresariopulpero.com', it redirects to '/medicor/home'
      if (domain === 'www.empresariopulpero.com' || domain === 'empresariopulpero.com') {
         navigate(`/medicor/home`);
         return;
      }
      // to enter in a shorter way through the url
      if (urlShort) {
         navigate(`/${slugCompany}/home`);
         return;
      }
      // if there is a configuration in localstorage you take its slug company
      if (configurationLS) {
         navigate(`/${configurationLS.slugCompany}/home`);
         return;
      }
      // default slug company
      navigate(`/${defaultSlug}/home`);
   }, []);

   return <></>;
}
