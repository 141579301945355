import { VideoPlayer, Loading, CustomError } from './library/components';
import { getConfiguration } from './shared/hooks/user-session.hook';
import { defaultStyles } from './shared/mocks/configuration.mock';
import { ICompanyInfo, IConfiguration } from './shared/interfaces';
import { HelmetProvider } from 'react-helmet-async';
import { customStyles } from './shared/utilities';
import { RootState } from './shared/redux/store';
import { withProfiler } from '@sentry/react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Router } from './router';
import configurationService from './shared/services/configuration/configuration.service';
import { updateMetaTagsByCompany } from './library/helpers';

function App() {
   // configuration
   const configurationLS: IConfiguration | null = getConfiguration();

   // states redux
   const loading = useSelector((state: RootState) => state.load.loading);
   const configurationStore: IConfiguration = useSelector((state: RootState) => state.configuration);

   // effects
   useEffect(() => {
      if (!configurationLS) {
         customStyles(defaultStyles);
      }
      if (configurationLS) {
         addMetaTags();
         customStyles(configurationLS.styles);
      }
   }, [configurationStore]);

   const addMetaTags = async () => {
      if (!configurationLS) return;
      let infoCompanyData: ICompanyInfo | null = null;
      try {
         const infoCompany = await configurationService.getInfoCompany(configurationLS.slugCompany);
         if (!infoCompany) throw new Error()
         infoCompanyData = infoCompany;
      }
      catch(err) {
         console.error('Error obteniendo la configuración de la empresa', err);
         return
      }
      const companyName = infoCompanyData?.name;
      const companyDescription = infoCompanyData?.description;
      const companyLogo = infoCompanyData.image_path;
     updateMetaTagsByCompany(companyName, companyDescription, companyLogo);
   }

   // this was done in case you want to disable the app
   if (import.meta.env.VITE_MAINTENANCE === 'true') {
      return <CustomError inMaintenance />;
   }

   return (
      <HelmetProvider>
         {loading && <Loading />}
         <Router />
         <VideoPlayer />
      </HelmetProvider>
   );
}

export default withProfiler(App);
