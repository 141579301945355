import { AuthUrl, CustomError, RedirectHome } from './library/components';
import { Routes, Route } from 'react-router-dom';
import { LazyLoader } from './lazy-loader';
import { lazy } from 'react';

export function Router(): JSX.Element {
   // configuration
   const pathConfiguration = '/:slugCompany';

   return (
      <Routes>
         {/* app */}
         <Route path='/logout' element={<></>} />
         <Route path='*' element={<RedirectHome />} />
         <Route path='/error/:status?' element={<CustomError />} />
         <Route path={`${pathConfiguration}`} element={<RedirectHome urlShort />} />
         <Route path={`${pathConfiguration}/auth/:token`} element={<AuthUrl />} />
         {/* pages */}
         <Route path={`${pathConfiguration}/home`} element={<HomePage />} />
         <Route path={`${pathConfiguration}/all-modules`} element={<AllModulesPage />} />
         <Route path={`${pathConfiguration}/modules/:slugModule`} element={<ModulePage />} />
         <Route path={`${pathConfiguration}/modules/:slugModule/lessons/:slugLesson/parts/:slugPart`} element={<PartPage />} />
         <Route path={`${pathConfiguration}/explore`} element={<ExplorePage />} />
         <Route path={`${pathConfiguration}/explore/modules/:slugModule/all-lessons`} element={<ViewModulePage />} />
         <Route path={`${pathConfiguration}/points`} element={<PointsPage />} />
         <Route path={`${pathConfiguration}/certifications`} element={<CertificationsPage />} />
         <Route path={`${pathConfiguration}/auth`} element={<AuthPage />} />
         <Route path={`${pathConfiguration}/change-password`} element={<ChangePasswordPage />} />
         <Route path={`${pathConfiguration}/saved`} element={<SavedPage />} />
         <Route path={`${pathConfiguration}/support`} element={<SupportPage />} />
         <Route path={`${pathConfiguration}/account`} element={<AccountPage />} />
         <Route path={`${pathConfiguration}/edit-account`} element={<EditDataAccountPage />} />
         <Route path={`${pathConfiguration}/edit-business`} element={<EditDataBusinessPage />} />
         <Route path={`${pathConfiguration}/awards`} element={<AwardsPage />} />
         <Route path={`${pathConfiguration}/awards/detail/:idAward`} element={<AwardDetailPage />} />
         <Route path={`${pathConfiguration}/awards-redeemed`} element={<AwardsRedeemedPage />} />
         <Route path={`${pathConfiguration}/points-history`} element={<HistoryPointsPage />} />
      </Routes>
   );
}

// import whith lazy
const HomePage = LazyLoader(lazy(() => import('./pages').then(({ HomePage }) => ({ default: HomePage }))));
const AllModulesPage = LazyLoader(lazy(() => import('./pages').then(({ AllModulesPage }) => ({ default: AllModulesPage }))));
const ModulePage = LazyLoader(lazy(() => import('./pages').then(({ ModulePage }) => ({ default: ModulePage }))));
const PartPage = LazyLoader(lazy(() => import('./pages').then(({ PartPage }) => ({ default: PartPage }))));
const ExplorePage = LazyLoader(lazy(() => import('./pages').then(({ ExplorePage }) => ({ default: ExplorePage }))));
const ViewModulePage = LazyLoader(lazy(() => import('./pages').then(({ ViewModulePage }) => ({ default: ViewModulePage }))));
const PointsPage = LazyLoader(lazy(() => import('./pages').then(({ PointsPage }) => ({ default: PointsPage }))));
const CertificationsPage = LazyLoader(lazy(() => import('./pages').then(({ CertificationsPage }) => ({ default: CertificationsPage }))));
const ChangePasswordPage = LazyLoader(lazy(() => import('./pages').then(({ ChangePasswordPage }) => ({ default: ChangePasswordPage }))));
const SavedPage = LazyLoader(lazy(() => import('./pages').then(({ SavedPage }) => ({ default: SavedPage }))));
const SupportPage = LazyLoader(lazy(() => import('./pages').then(({ SupportPage }) => ({ default: SupportPage }))));
const AccountPage = LazyLoader(lazy(() => import('./pages').then(({ AccountPage }) => ({ default: AccountPage }))));
const EditDataAccountPage = LazyLoader(lazy(() => import('./pages').then(({ EditDataAccountPage }) => ({ default: EditDataAccountPage }))));
const EditDataBusinessPage = LazyLoader(
   lazy(() => import('./pages').then(({ EditDataBusinessPage }) => ({ default: EditDataBusinessPage })))
);
const AwardsPage = LazyLoader(lazy(() => import('./pages').then(({ AwardsPage }) => ({ default: AwardsPage }))));
const AwardDetailPage = LazyLoader(lazy(() => import('./pages').then(({ AwardDetailPage }) => ({ default: AwardDetailPage }))));
const AwardsRedeemedPage = LazyLoader(lazy(() => import('./pages').then(({ AwardsRedeemedPage }) => ({ default: AwardsRedeemedPage }))));
const AuthPage = LazyLoader(lazy(() => import('./pages').then(({ AuthPage }) => ({ default: AuthPage }))));
const HistoryPointsPage = LazyLoader(lazy(() => import('./pages').then(({ HistoryPointsPage }) => ({ default: HistoryPointsPage }))));