import { IConfiguration } from '../interfaces';

export const customStyles = (customStyles: IConfiguration['styles']): void => {
   const windowHeight = window.innerHeight;
   // Define CSS variables based on the customStyles object
   const styleEnvironments: string = `
      :root {
         --primary-color: ${customStyles.primaryColor};
         --secondary-color: ${customStyles.secondaryColor};
         --black-custom: ${customStyles.blackCustom};
         --white-custom: ${customStyles.whiteCustom};
         --background-pages: ${customStyles.backgroundPages};
         --button-text-color: ${customStyles.textButtonColor || '#000000'};
         --bar-reproduction-color: ${customStyles.barReproductionColor || '#ffffff'};
         --background-button:  ${customStyles.backgroundButton || '#ffffff'};
         --color-button:  ${customStyles.textButton || '#000000'};
         --shadow-color:  ${customStyles.shadowColor || '#000000'};
         --backgorund-register:  ${customStyles.backgroundRegister || '#000000'};
         --color-register:  ${customStyles.colorRegister || '#ffffff'};
      }
      .dvh-compatible {
         height: ${windowHeight}px;
      }
   `;
   // Create a style element and inject the defined CSS variables
   const styleElement: HTMLStyleElement = document.createElement('style');
   styleElement.innerHTML = styleEnvironments;
   // Append the style element to the document head
   document.head.appendChild(styleElement);
};
