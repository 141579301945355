import configurationReducer from './features/configuration/configuration.slice';
import { AnyAction, ThunkMiddleware, configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import videoReducer from './features/video/video.slice';
import loadReducer from './features/load/load.slice';

export const store: ToolkitStore<any, AnyAction, [ThunkMiddleware<any, AnyAction>]> = configureStore({
   reducer: {
      load: loadReducer,
      video: videoReducer,
      configuration: configurationReducer
   }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
