import { getSession } from './user-session.hook';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';

export function eventTrack(event: string, body: Record<any, any>, slugCompany?: string): void {
   // configuration
   const session = getSession();

   if (
      import.meta.env.VITE_BUILD_ENV === 'production' &&
      import.meta.env.VITE_MIXPANEL_TOKEN &&
      import.meta.env.VITE_G_ANALYTICS_TOKEN &&
      mixpanel.people &&
      slugCompany
   ) {
      if (session && session.area) {
         mixpanel.track(event, {
            company_slug: slugCompany,
            area_name: session.area.name,
            area_id: session.area.id,
            ...body
         });

         ReactGA.event(event, {
            company_slug: slugCompany,
            area_name: session.area.name,
            area_id: session.area.id,
            ...body
         });

         return;
      }

      mixpanel.track(event, { ...body, company_slug: slugCompany });

      ReactGA.event(event, { ...body, company_slug: slugCompany });
   }
}
