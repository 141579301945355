import { getConfiguration } from '../../../shared/hooks/user-session.hook';
import { IConfiguration } from '../../../shared/interfaces';
import packageJson from '../../../../package.json';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from './loading.module.scss';

export function Loading(): JSX.Element {
   // configuration
   const location = useLocation();
   const { version } = packageJson;
   const configurationLS: IConfiguration | null = getConfiguration();
   const currentRoute = location.pathname.split('/')[2]; // domain/path1/path2

   // states
   const [currentImage, setCurrentImage] = useState<string>('');

   // effects
   useEffect(() => {
      if (configurationLS) {
         if (currentRoute === 'home') {
            if (configurationLS.images && configurationLS.images.logoURL) {
               setCurrentImage(configurationLS.images.logoURL);
               return;
            }
         }
         if (configurationLS.images && configurationLS.images.logoTransitionURL) {
            setCurrentImage(configurationLS.images.logoTransitionURL);
            return;
         }
         setCurrentImage('/images/splash-loading.svg');
      }
   }, []);

   return (
      <div className='dvh-compatible absolute !h-[100dvh] w-screen bg-primary-color z-[10]'>
         <div className='realtive h-full flex flex-col justify-center items-center'>
            {currentImage && <img src={currentImage} alt='geep-loading' className={`w-[150px] ${styles.splash}`} />}
            {!currentImage && !configurationLS && (
               <img src='/images/splash-loading.svg' alt='geep-loading' className={`w-[150px] ${styles.splash}`} />
            )}
            <span className='absolute bottom-[20px]'>{`Versión ${version}`}</span>
         </div>
      </div>
   );
}
