import {
   IBodyNumberVerification,
   IResponseRefreshToken,
   IBodyOtpVerification,
   IBodyChangePassword,
   IBodyForgotPassword,
   IResponseRegister,
   IUserLocalStorage,
   IResponseLogin,
   IBodyRegister,
   IBodyLogin,
   IBodyCheckPhone
} from '../../interfaces';
import { ResponseHttpCall } from '../../interfaces/services/http-call.interface';
import HttpCall from '../http-call.service';

class AuthService {
   public async sendNumberVerification(body: IBodyNumberVerification): Promise<ResponseHttpCall<unknown>> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/otp/phone`;
      const verificationResponse: ResponseHttpCall<unknown> = await HttpCall.post(url, body);
      return verificationResponse;
   }

   public async sendOtpVerification(body: IBodyOtpVerification): Promise<ResponseHttpCall<unknown>> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/otp/phone/verify`;
      const verificationResponse: ResponseHttpCall<unknown> = await HttpCall.post(url, body);
      return verificationResponse;
   }

   public async register(slugCompany: string, body: IBodyRegister): Promise<ResponseHttpCall<IResponseRegister>> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/auth/${slugCompany}/register`;
      const registerResponse: ResponseHttpCall<IResponseRegister> = await HttpCall.post(url, body);
      if (registerResponse.data && registerResponse.data.authorization && registerResponse.data.user) {
         const user: IUserLocalStorage = {
            id: registerResponse.data.user.id,
            name: registerResponse.data.user.name ?? '',
            code: registerResponse.data.user.code,
            phone: registerResponse.data.user.phone,
            email: registerResponse.data.user.email,
            lastNameFather: registerResponse.data.user.last_name_father ?? '',
            lastNameMother: null,
            birthdate: null,
            gender: null,
            avatarId: null,
            avatarUrl: '',
            area: registerResponse.data.user.default_area,
            token: registerResponse.data.authorization.token,
            acceptTermsConditions: registerResponse.data.user.current_acceptance_terms || false
         };
         localStorage.setItem('user', JSON.stringify(user));
      }
      return registerResponse;
   }

   public async login(slugCompany: string, body: IBodyLogin): Promise<ResponseHttpCall<IResponseLogin>> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/auth/${slugCompany}/login`;
      const loginResponse: ResponseHttpCall<IResponseLogin> = await HttpCall.post(url, body);
      if (loginResponse.data && loginResponse.data.authorization && loginResponse.data.user) {
         const user: IUserLocalStorage = {
            id: loginResponse.data.user.id,
            name: loginResponse.data.user.name ?? '',
            code: loginResponse.data.user.code,
            phone: loginResponse.data.user.phone,
            email: loginResponse.data.user.email,
            lastNameFather: loginResponse.data.user.last_name_father ?? '',
            lastNameMother: loginResponse.data.user.last_name_mother ?? '',
            birthdate: loginResponse.data.user.date_of_birth,
            gender: loginResponse.data.user.gender,
            avatarId: loginResponse.data.user.avatar_id,
            avatarUrl: '',
            area: loginResponse.data.user.default_area,
            token: loginResponse.data.authorization.token,
            acceptTermsConditions: loginResponse.data.user.current_acceptance_terms || false
         };
         localStorage.setItem('user', JSON.stringify(user));
      }
      return loginResponse;
   }

   public async changePassword(body: IBodyChangePassword): Promise<ResponseHttpCall<unknown>> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/auth/password`;
      const response: ResponseHttpCall<unknown> = await HttpCall.patch(url, body);
      return response;
   }

   public async forgotPassword(body: IBodyForgotPassword): Promise<ResponseHttpCall<unknown>> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/auth/reset`;
      const response: ResponseHttpCall<unknown> = await HttpCall.post(url, body);
      return response;
   }

   public async refreshToken(slugCompany: string): Promise<ResponseHttpCall<IResponseRefreshToken>> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/auth/${slugCompany}/refresh`;
      const response: ResponseHttpCall<IResponseRefreshToken> = await HttpCall.post(url);
      return response;
   }

   public async checkPhone(phone: IBodyCheckPhone): Promise<ResponseHttpCall<unknown>> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/auth/check_phone_exists`;
      const response: ResponseHttpCall<unknown> = await HttpCall.post(url, phone);
      return response;
   }
}

export default new AuthService();
