export const LIST_SURVEY_LETTERS: string[] = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
]

export const ROUTES_WITHOUT_REFRESH_TOKEN: string[] = [
  'auth',
  '401',
  'invalid'
]