import { BrowserRouter } from 'react-router-dom';
import { store } from './shared/redux/store';
import './shared/languages/language.service';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import 'classlist-polyfill';
import App from './App';
import './global.scss';
import 'dom4';

if (import.meta.env.VITE_BUILD_ENV === 'production') {
   Sentry.init({
      dsn: 'https://387294a9534c6e4fddbf35c0cb52cefe@o4506125427474432.ingest.sentry.io/4506125432127488',
      environment: import.meta.env.BUILD_ENV || 'development',
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      // performance Monitoring
      tracesSampleRate: 1.0, // capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
   });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
   //<React.StrictMode>
      <Provider store={store}>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </Provider>
   // </React.StrictMode>
);
