import { IResponseRefreshToken, IUserLocalStorage, ResponseHttpCall } from '../interfaces';
import AuthService from '../services/auth/auth.service';

export enum EMessage {
   Invalid = 'invalid',
   Expired = 'expired-session'
}

export async function refreshTokenHook(type?: { message: EMessage; slugCompany: string }): Promise<void> {
   const slugCompany: string = window.location.pathname.split('/')[1];
   const responseRefreshToken: ResponseHttpCall<IResponseRefreshToken> = await AuthService.refreshToken(slugCompany);
   if (responseRefreshToken.failed) {
      localStorage.removeItem('user');
      if (type && type.message === EMessage.Invalid) {
         window.location.href = `/error/invalid`;
         return;
      }
      window.location.href = `/error/401`;
      return;
   }
   if (!responseRefreshToken.failed && responseRefreshToken.data.authorization.token) {
      const user: string | null = localStorage.getItem('user');
      if (user) {
         try {
            let updateUser: IUserLocalStorage = JSON.parse(user);
            if (Object.keys(updateUser).length === 0) {
               localStorage.removeItem('user');
               window.location.href = `/error/401`;
               return;
            }
            updateUser = {
               ...updateUser,
               token: responseRefreshToken.data.authorization.token
            };
            localStorage.setItem('user', JSON.stringify(updateUser));
            if (type && type.message === EMessage.Invalid) {
               if (type.slugCompany) {
                  window.location.href = `/${type.slugCompany}/home`;
                  return;
               }
            }
            window.location.reload();
         }
         catch (error) {
            console.error('error updating the user:', error);
            localStorage.removeItem('user');
            window.location.href = `/error/401`;
         }
      }
   }
}
