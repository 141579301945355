import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface ILoad {
   loading: boolean;
}

const initialState: ILoad = {
   loading: true
};

export const loadSlice = createSlice({
   name: 'load',
   initialState,
   reducers: {
      loadStarted: (state) => {
         state.loading = true;
      },
      loadFinished: (state) => {
         state.loading = false;
      },
      loadingState: (state, action: PayloadAction<boolean>) => {
         state.loading = action.payload;
      }
   }
});

export const { loadStarted, loadFinished, loadingState } = loadSlice.actions;

export default loadSlice.reducer;
